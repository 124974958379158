export const LINKS = {
	CVVC: 'https://cvvc.com/cvlabs',
	HTP: 'https://www.park.by/en/?fromlang=Y',
	WEB_APP_SIGNIN: 'https://app.sharekey.com/signin',
	WEB_APP_SIGNUP: 'https://app.sharekey.com/signup',
	PRIVACY_POLICY: '/info/privacy-policy.pdf',
	TERMS_OF_SERVICE: '/info/terms-of-service.pdf',
	BROCHURE: '/info/sharekey-brochure.pdf',
	SECURITY_PAPER: '/info/sharekey-securitypaper.pdf',
	VIMEO_VIDEO: 'https://vimeo.com/516660893/145317b64c',
	SUPPORT: '/support',
	SOURCE_CODE: 'https://github.com/sharekey',
};
